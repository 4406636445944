<template v-if="isLoggedIn">
    <div class="container page" v-if="member.clusterCode=='stm'">

        <div class="row">
            <div class="col-sm-6 font-weight-bold">
                <h5>CEO Award Vote</h5>
            </div>

            <div class="col-sm-3">

            </div>
        </div>

        <content-db contentKey="ceoawardvote" :isCache="false"></content-db>

        <div class="card card-default" style="min-width:420px">
            <b-card-header header-tag="nav">
                <b-nav card-header tabs>
                    <b-nav-item :active="selTab=='Everyday Hero Award'" @click="selTab='Everyday Hero Award'">Everyday Hero Award</b-nav-item>
                    <b-nav-item :active="selTab=='Living Purposeful Award'" @click="selTab='Living Purposeful Award'">Living Purposeful Award</b-nav-item>
                </b-nav>
            </b-card-header>

            <div class="card-body">


                <div v-if="hasRunOnce && !isLoading" style="font-size:0.8rem">
                    <div v-for="f in recsFiltered" :key="f._rowNum" class="mb-2 mt-3" style="page-break-inside:avoid;page-break-before:always;display:block">
                        <div class="card card-default">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-6" style="font-size:1.2rem">Nomination for: <b>{{ f.awardCat }}</b></div>
                                    <div class="col-md-4 text-right" style=""><b>Ref:</b> {{ f.ceoawardId.sjRemoveIntHash() }}</div>
                                    <div class="col-md-2 text-right" style="">
                                        <b-button v-if="!f.hasVoted" pill variant="primary" class="mr-5" size="sm" @click="onVote(f.ceoawardId, f.awardCat)">
                                            <b-icon icon="hand-thumbs-up" aria-hidden="true" class="mr-1"></b-icon> Vote
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <!--<div class="mb-3">
                                    <div class="fldNameC">Nominees:</div>
                                    <div class="fldValueC">{{ (f.nominees) ? f.nominees.sjReplaceAll(';','\r\n') : '' }}</div>
                                </div>-->

                                <div class="mb-3">
                                    <div class="fldNameC">Motivation:</div>
                                    <div class="fldValueC">{{ f.motivation }}</div>
                                </div>
                                <!--<div class="mb-3">
                                    <div class="fldNameC">Action:</div>
                                    <div class="fldValueC">{{ f.actions }}</div>
                                </div>
                                <div class="mb-3">
                                    <div class="fldNameC">Measures:</div>
                                    <div class="fldValueC">{{ f.measures }}</div>
                                </div>-->
                            </div>
                        </div>
                    </div>


                </div>


            </div><!--card-body-->
        </div>


        <!--
    <table v-if="hasRunOnce && !isLoading" class="table table-hover table-condensed" style="font-size:0.8rem">
        <tr>
            <th>Nomination</th>
            <th>Motivation/Actions/Measures/Achievements</th>
            <th>Nominated By</th>
            <th>Nominees</th>
        </tr>

        <tr v-for="f in report" :key="f._rowNum">
            <td>
                <div><b>Ref: {{ f.ceoawardId }}</b></div>
                <div>{{ f.nominatedDate.sjToDate('d MMM yyyy HH:mm') }}</div>
                <div>{{ f.refInfo }}</div>
                <div class="mt-2"><b>{{ f.awardCat }}</b></div>
                <div class="mt-2"><b>{{ f.awardStatusDesc }}</b></div>

            </td>
            <td class="preline">
                <div><b>Motivation:</b></div>
                <div>{{ f.motivation }}</div>
                <div><b>Actions:</b></div>
                <div>{{ f.actions }}</div>
                <div><b>Measures:</b></div>
                <div>{{ f.measures}}</div>
                <div><b>Achievements:</b></div>
                <div>{{ f.achievements }}</div>
            </td>
            <td>
                <div><b>Nominated By:</b></div>
                <div>{{ f.nominatedByMember }}</div>
                <div><b>Seconded By:</b></div>
                <div>{{ f.nominatorMember1 }}</div>
                <div>{{ f.nominatorMember2 }}</div>
            </td>
            <td class="preline">{{ f.nominees.sjReplaceAll(';','\r\n') }}</td>
        </tr>
    </table>
    -->
        <div v-if="isLoading"><i>Loading...</i></div>
        <div v-if="hasRunOnce && !isLoading && report.length==0"><i>No Results</i></div>

    </div>

   
</template>

<script>
    // @ is an alias to /src
    import axios from 'axios'
    import sjData from '@/sjUtilData'
	import ContentDb from '@/components/ContentDb';


    export default {
        name: 'ReportCEOAward',
		components: { ContentDb },
        data() {
            return {
                monthList: [],
                selMonthFrom: null,
                selMonthTo: null,
                awardStatus: 'N',
                report: [],
                isLoading: false,
                hasRunOnce: false,
				selTab: 'Everyday Hero Award'
            }
        },
        computed: {
            member() {
                return this.$store.state.memberStore.member
            },

            isLoggedIn() {
                return this.$store.state.isLoggedIn
            },

            recsFiltered() {
				return this.report.filter(n => n.awardCat == this.selTab);
			}
        },

        created() {
        },
        mounted() {
            this.loadReport();
            //axios.post('/api/member/memberget', {})
            //	.then(({ data }) => {
            //		//this.roles = data.roles;
            //		//this.noms = new sjData(data.noms).data;
            //		//this.displayPart = 1;
            //	})
            //	.catch((error) => {
            //		if (error) {
            //			this.msgBox(error.response.data.message);
            //		}
            //	})
        },
        watch: {
            //selMonth: function () {
            //    if (this.selMonth !== null) this.loadReport();
            //},
            //awardStatus: function () {
            //    if (this.awardStatus !== null) this.loadReport();
            //}
        },

        methods: {
            loadReport() {
                this.isLoading = true;
                this.report = [];
                this.awardStatus = 'N';// Santam CEO Award - no system intervention
				axios.post('/api/groupceoaward/ceoawardlistvote', { })
                    .then(({ data }) => {
                        this.isLoading = false;
                        this.hasRunOnce = true;
                        this.report = new sjData(data.report).data;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (error) {
                            this.msgBox(error.response.data.message);
                        }
                    })
            },

			onVote(ceoawardId, votefor) {

				return axios
					.post(
						'/api/groupceoaward/vote', { ceoawardId: ceoawardId, votefor: votefor }
					)
                    .then(() => {
                        this.report = [];
                        this.loadReport();
						const newTab = this.selTab == 'Everyday Hero Award' ? 'Living Purposeful Award' : 'Everyday Hero Award';
                        this.msgBox('You have successfully voted for: ' + votefor + '. ' + 'You may now vote for: ' + newTab + ' (If you have not done so already)');

                        this.selTab = newTab;
					})
					.catch((error) => {
						this.msgBox(error.response.data.message);
					});
			},




			exportToCSV(mode, isAlt) {
				//All: line breaks in cell are only \n 0A

				//isAlt False (Windows) - end of row: \r\n 0D 0A
				//isAlt True (Mac) - end of row: \r 0D

				//mode: utf - file prefix: EF BB BF
				//mode: ascii - file prefix: none
				//mode: compat - replace all line breaks in cell with - (dash)

				const cellLineBreak = mode === "compat" ? "-" : "\n";
				const rowLineBreak = isAlt ? "\r" : "\r\n";
				const filePrefix = mode === "utf" ? "\uFEFF" : "";  // nb: "\uFEFF" generates byte order mark "\xEF\xBB\xBF"
				const isAscii = mode === "ascii";
				const asciiReplaceChar = " "; //"\u00B7";

				let fx = function (val) {
					try {
						let s = (val + "").trim(); //https://blog.zsec.uk/csv-dangers-mitigations/
						while (s.length > 1 && s.indexOf("=") == 0 || s.indexOf("+") == 0 || s.indexOf("@") == 0 || (!isNaN(s) && s.indexOf("-") == 0)) {
							s = s.substr(1).trim();
						}

						if (s.indexOf("@") > -1 || s.indexOf("+") > -1 || s.indexOf("-") > -1 || s.indexOf("=") > -1 || s.indexOf("|") > -1 || s.indexOf("%") > -1) {
							s = "'" + s.sjReplaceAll("|", "\\|") + "'";
						}

						if (s.indexOf("\"") > -1 || s.indexOf("\r") > -1 || s.indexOf("\n") > -1 || s.indexOf(",") > -1) {
							if (isAscii) s = s
								.sjReplaceAll("U+201C", '"')
								.sjReplaceAll("U+201D", '"')
								.sjReplaceAll("U+2018", "'").sjReplaceAll("�", "'")
								.sjReplaceAll("U+2019", "'")
								.replace(/[\u{00FF}-\u{FFFF}]/gu, asciiReplaceChar);
							s = "\"" + s.sjReplaceAll("\r\n", cellLineBreak).sjReplaceAll("\r", cellLineBreak).sjReplaceAll("\n", cellLineBreak) + "\"";

						}
						return s;
					} catch {
						return 'Error';
					}
				}

				let csv = filePrefix + 'Ref,Date,Award Category,Motivation,Actions,Measures,Achievements,Nominated By,Status,Info,Nominees\r\n';

                for (var n = 0; n < this.report.length; n++) {
                    let f = this.report[n];
                    //debugger;
					csv += `${f.ceoawardId},${fx(f.nominatedDate.sjToDate('d MMM yyyy HH:mm'))},${fx(f.awardCat)},${fx(f.motivation)},${fx(f.actions)},${fx(f.measures)},${fx(f.achievements)},${fx(f.nominatedByMember)},${fx(f.awardStatusDesc)},${fx(f.refInfo)},${fx(f.nominees)}${rowLineBreak}`;
                }


                var pom = document.createElement('a');
                var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                var url = URL.createObjectURL(blob);
                pom.href = url;
                pom.setAttribute('download', 'report.csv');
                pom.click();
            },
            windowPrint() {
                window.print();
            },
            msgBox(msg, title) {
                this.$bvModal.msgBoxOk(msg, { title: typeof title === 'undefined' ? 'Please note:' : title, centered: true })
            }


        }

    }
</script>
